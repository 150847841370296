import React, { Suspense } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

// Preloader
const Preloader = React.lazy(() => import('./components/layouts/Preloader'));

const Homethree = React.lazy(() => import('./components/pages/Homethree'));
// Pages
const About = React.lazy(() => import('./components/pages/About'));
const Faq = React.lazy(() => import('./components/pages/Faq'));
const Contact = React.lazy(() => import('./components/pages/Contact'));
const Comingsoon = React.lazy(() => import('./components/pages/Comingsoon'));
const Error = React.lazy(() => import('./components/pages/Error'));
const Legal = React.lazy(() => import('./components/pages/Legal'));
const Privacy = React.lazy(() => import('./components/pages/Privacy'));
// Listings
const Listinggrid = React.lazy(() => import('./components/pages/Listinggrid'));

const Listingdetailsthree = React.lazy(() =>
  import('./components/pages/Listingdetailsthree')
);
const Submitlisting = React.lazy(() =>
  import('./components/pages/Submitlisting')
);
// Agents
const Agentarchive = React.lazy(() =>
  import('./components/pages/Agentarchive')
);
const Agentdetails = React.lazy(() =>
  import('./components/pages/Agentdetails')
);
// Agency
const Agencyarchive = React.lazy(() =>
  import('./components/pages/Agencyarchive')
);
const Agencydetails = React.lazy(() =>
  import('./components/pages/Agencydetails')
);

function App() {
  return (
    <Router>
      <Suspense fallback={<div></div>}>
        <Preloader />
        <Switch>
          {/* Pages */}
          <Route path='/about' component={About} />
          <Route path='/faq' component={Faq} />
          <Route path='/contact' component={Contact} />
          <Route path='/coming-soon' component={Comingsoon} />
          <Route path='/error-404' component={Error} />
          <Route path='/privacy' component={Privacy} />
          {/* Listings */}
          <Route path='/listings/:listingId' component={Listingdetailsthree} />
          <Route path='/listings' component={Listinggrid} />
          <Route path='/submit-listing' component={Submitlisting} />
          {/* Agents */}
          <Route path='/agents/:agentId' component={Agentdetails} />
          <Route path='/agents' component={Agentarchive} />
          {/* Agency */}
          <Route path='/' component={Homethree} />
        </Switch>
      </Suspense>
    </Router>
  );
}

export default App;
